/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const ExternalLink = ({ url, children, color }) => (
  <a
    href={url}
    sx={{
      color: color,
      py: 1,
      position: "relative",
      // overflow: "hidden",
      display: "inline-block",
      ":after": {
        content: '" "',
        position: "absolute",
        right: 0,
        width: 0,
        top: "28px",
        background: color,
        height: "1px",
        transition: "width 0.3s ease-out",
      },
      ":hover, :focus, :active": {
        color: color,
        ":after": {
          left: 0,
          right: "auto",
          width: "100%",
        },
      },
    }}
    target='_blank'
    rel='noopener noreferrer'
  >
    {children}
  </a>
);

export default ExternalLink;

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
};
