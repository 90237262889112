import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

const ConsentCookie = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="consentCookie"
      style={{ background: "black" }}
      buttonStyle={{
        backgroundColor: "white",
        color: "black",
        fontSize: "12px",
        textTransform: "uppercase",
      }}
      expires={150}
    >
      <span style={{ fontSize: "10px" }}>
        We use cookies to ensure that we give you the best experience on our
        website.{" "}
        <Link
          style={{
            fontSize: "12px",
            color: "#545454",
            textDecoration: "underline",
          }}
          to={"/gdpr"}
        >
          Click here
        </Link>{" "}
        for more information.
      </span>
    </CookieConsent>
  );
};

export default ConsentCookie;
