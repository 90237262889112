/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { useState, useContext, useEffect } from "react";
import Hamburger from "./Hamburger";
import Dropdown from "./DropDown";
import PlusBtn from "./PlusBtn";
import useDocumentScrollThrottled from "../../hooks/useDocumentScrollThrottled";
import { CartContext } from "../../providers/CartProvider";
import ShoppingBagIcon from "./ShoppingBagIcon";
import { useLocation } from "@reach/router";
import { useCart } from "../../hooks/useCart";

const StyledLink = ({ href, children, handleclick }) => (
  <Link
    to={href}
    onClick={handleclick}
    sx={{
      marginY: "14px",
      textTransform: "uppercase",
      color: "text",
      ml: "22px",
    }}
  >
    {children}
  </Link>
);

const AccordionContainer = ({ maxHeight, children }) => (
  <div
    sx={{
      maxHeight: maxHeight,
      overflow: "hidden",
      transition: "max-height .5s ease-in-out",
      display: "flex",
      flexDirection: "column",
      a: {
        ml: "24px",
        color: "text",
        textTransform: "uppercase",
        fontSize: 2,
        my: 1,
      },
    }}
  >
    {children}
  </div>
);

const Navbar = () => {
  const { globalState, dispatch } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [maxheight, setMaxheight] = useState("0px");
  const [maxheight2, setMaxheight2] = useState("0px");
  const [showen, setShowen] = useState(false);
  const [showen2, setShowen2] = useState(false);
  const { initializeCart } = useCart();

  const [landingpage, setLandingPage] = useState(false);
  const location = useLocation();

  function closeMenu() {
    setTimeout(setOpen(false), 1000);
  }

  useEffect(() => {
    initializeCart();
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/brand" ||
      location.pathname === "/services"
    ) {
      setLandingPage(true);
    }
  }, [location.pathname]);

  const [showBackground, setBackground] = useState(false);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);

  const MINIMUM_SCROLL = 40;
  const TIMEOUT_DELAY = 50;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown =
      previousScrollTop < currentScrollTop && currentScrollTop > MINIMUM_SCROLL;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    // adds a bit of delay effect to the header’s hide/show movement after scrolling
    setTimeout(() => {
      setShouldHideHeader(isScrolledDown);
      setBackground(isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <nav
      sx={{
        position: "fixed",
        zIndex: 10,
        width: "100%",
        height: "70px",
        background: showBackground ? "#fafafa" : "transparent",
        transition: "transform .3s cubic-bezier(.5,0,.5,1), background .5s",
        transform: shouldHideHeader ? "translateY(-100%)" : "translateY(0%)",
      }}
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
          px: [4, 5],
          height: "100%",
        }}
      >
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            height: "100%",
            position: "absolute",
            button: {
              mt: "3px",
            },
          }}
        >
          <Hamburger
            open={open}
            setOpen={setOpen}
            showBackground={showBackground}
            landingpage={landingpage}
          />
        </div>
        <div sx={{ marginX: "auto" }}>
          <Link
            onClick={closeMenu}
            to="/"
            sx={{
              fontSize: [5, 6],
              color: showBackground || !landingpage ? "black" : "white",
              ":hover, :active, :focus": {
                color: showBackground || !landingpage ? "black" : "white",
              },
            }}
          >
            HAHA studio
          </Link>
        </div>
      </div>

      <Dropdown open={open} closeMenu={closeMenu}>
        <StyledLink href="/products" handleclick={closeMenu}>
          Products
        </StyledLink>
        <StyledLink href="/projects" handleclick={closeMenu}>
          Projects
        </StyledLink>
        <PlusBtn
          showen={showen}
          setShowen={setShowen}
          setMaxheight={setMaxheight}
          text="The Company"
        />
        <AccordionContainer maxHeight={maxheight}>
          <Link to="/services" onClick={closeMenu}>
            Services
          </Link>
          <Link to="/brand" onClick={closeMenu}>
            Brand
          </Link>
        </AccordionContainer>
        <PlusBtn
          showen={showen2}
          setShowen={setShowen2}
          setMaxheight={setMaxheight2}
          text="Contact"
        />
        <AccordionContainer maxHeight={maxheight2}>
          <Link to="/contact" onClick={closeMenu}>
            Find us
          </Link>
          <Link to="/retailers" onClick={closeMenu}>
            Retailers
          </Link>
          <Link to="/press" onClick={closeMenu}>
            Press
          </Link>
        </AccordionContainer>
      </Dropdown>
    </nav>
  );
};

export default Navbar;
