/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { toggleText } from "../../utils/functions";

const PlusBtn = ({ showen, setShowen, setMaxheight, text }) => {
  const burgerLine = (action) => {
    let animation = {};
    if (showen) {
      switch (action) {
        case "hide":
          animation.backgroundColor = "transperent";
          break;
        default:
      }
    }
    return {
      display: "block",
      padding: "0",
      height: "2px",
      borderRadius: "2px",
      backgroundColor: "text",
      ":first-of-type": {
        width: "10px",
        mt: "5px",
      },
      ":nth-of-type(2)": {
        width: "10px",
        transform: "rotate(90deg)",
        mt: "-2px",
      },
      transition: "ease-in-out 0.3s",
      ...animation,
    };
  };

  return (
    <button
      onClick={() => toggleText(showen, setShowen, setMaxheight)}
      sx={{
        position: "relative",
        border: "none",
        outline: "none",
        padding: "0",
        cursor: "pointer",
        background: "transparent",
        display: "flex",
        alignItems: "center",
        fontSize: 4,
      }}
    >
      <div sx={{ position: "relative" }}>
        <span sx={burgerLine} />
        <span sx={burgerLine("hide")} />
      </div>
      <p
        sx={{
          pl: "12px",
          textTransform: "uppercase",
          my: "14px",
          color: "text",
        }}
      >
        {text}
      </p>
    </button>
  );
};

export default PlusBtn;

PlusBtn.propTypes = {
  showen: PropTypes.bool.isRequired,
  setShowen: PropTypes.func.isRequired,
  setMaxheight: PropTypes.func.isRequired,
};
