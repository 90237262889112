/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Fragment } from "react";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const DropDown = ({ children, open, closeMenu }) => {
  useLockBodyScroll(open);

  const slide = (action) => {
    let animation = {};
    if (open) {
      switch (action) {
        case "dropdown":
          animation.transform = "translate3d(0vw, 0, 0)";
          break;
        default:
      }
    }
    return {
      position: "fixed",
      backgroundColor: "white",
      left: 0,
      top: 0,
      transform: [
        "translate3d(-100vw, 0, 0)",
        null,
        "translate3d(-30vw, 0, 0)",
      ],
      width: ["100vw", null, "30vw"],
      height: "100vh",
      zIndex: 10,
      transition: "all 0.3s ease-out ",
      color: "text",
      display: "flex",
      flexDirection: "column",
      pt: 6,
      px: ["48px"],
      fontSize: 4,
      ...animation,
    };
  };

  const overlay = (action) => {
    let animation = {};
    if (open) {
      switch (action) {
        case "toggleoverlay":
          animation.display = "block";
          animation.opacity = "0.4";
          break;
        default:
      }
    }
    return {
      position: "fixed",
      backgroundColor: "black",
      left: 0,
      top: 0,
      display: "none",
      width: "100vw",
      height: "100vh",
      zIndex: 8,
      opacity: 0,
      transition: "all 0.3s ease-out ",
      ...animation,
    };
  };

  return (
    <Fragment>
      <div onClick={closeMenu} sx={overlay("toggleoverlay")} />
      <div sx={slide("dropdown")}>{children}</div>
    </Fragment>
  );
};

export default DropDown;

DropDown.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};
