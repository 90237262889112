function useLockBodyScroll(open) {
  if (typeof window !== `undefined` && open) {
    document.documentElement.style.overflowY = "hidden";
  } else if (typeof window !== `undefined` && !open) {
    document.documentElement.style.overflowY = "scroll";
  } else {
    return null;
  }
}

export default useLockBodyScroll;
