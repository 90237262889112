export function findProducts(productTitles, products) {
  const productArray = productTitles.map((product) => {
    let featuredProduct = products.find(
      (f) => f.node.frontmatter.title === product
    );
    return featuredProduct;
  });
  return productArray;
}

export function toggleText(open, setOpen, changeHeight) {
  if (!open) {
    changeHeight("90px");
    setOpen(!open);
  } else {
    changeHeight("0px");
    setOpen(!open);
  }
}

export function renderSnippet(htmlSnippet) {
  var checkoutContainer = document.getElementById("my-checkout-container");
  checkoutContainer.innerHTML = htmlSnippet;
  var scriptsTags = checkoutContainer.getElementsByTagName("script");
  // This is necessary otherwise the scripts tags are not going to be evaluated
  for (var i = 0; i < scriptsTags.length; i++) {
    var parentNode = scriptsTags[i].parentNode;
    var newScriptTag = document.createElement("script");
    newScriptTag.type = "text/javascript";
    newScriptTag.text = scriptsTags[i].text;
    parentNode.removeChild(scriptsTags[i]);
    parentNode.appendChild(newScriptTag);
  }
}
