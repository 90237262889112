/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const Hamburger = ({ open, setOpen, showBackground, landingpage }) => {
  const burgerLine = (action) => {
    let animation = {};
    if (open) {
      switch (action) {
        case "rotateCW":
          animation.transform = "rotate(-135deg) translate(-3px, -2px)";
          animation.backgroundColor = "black";
          break;
        case "rotateCCW":
          animation.transform = "rotate(135deg) translate(-3px, 2px)";
          animation.backgroundColor = "black";
          break;
        default:
      }
    }
    return {
      display: "block",
      padding: "0",
      height: "2px",
      backgroundColor: showBackground || !landingpage ? "black" : "white",
      marginY: "5px",
      borderRadius: "2px",
      width: "20px",
      transition: "ease-in-out 0.3s",
      ...animation,
    };
  };

  return (
    <button
      onClick={() => setOpen(!open)}
      sx={{
        border: "none",
        outline: "none",
        height: "50px",
        width: "20px",
        padding: "0",
        position: "relative",
        cursor: "pointer",
        background: "transparent",
        zIndex: 90,
      }}
    >
      <div
        sx={{
          position: "relative",
          height: "50px",
          width: "20px",
        }}
      >
        <div
          sx={{
            position: "absolute",
            top: "50%",
            left: ["12px", "50%"],
            transform: "translate(-50%, -50%)",
          }}
        >
          <span sx={burgerLine("rotateCW")} />
          <span sx={burgerLine("rotateCCW")} />
        </div>
      </div>
      <span
        sx={{
          fontSize: 3,
          display: ["none", "block"],
          color:
            (showBackground && !open) || (!landingpage && !open)
              ? "black"
              : "white",
          position: "absolute",
          top: "50%",
          transform: "translate(70%, -50%)",
        }}
      >
        Menu
      </span>
    </button>
  );
};

export default Hamburger;

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  showBackground: PropTypes.bool.isRequired,
  landingpage: PropTypes.bool.isRequired,
};
