/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext } from "react";
import { CartContext } from "../providers/CartProvider";
import { DropdownContext } from "../providers/DropdownProvider";

import Img from "gatsby-image";
import { Link } from "gatsby";

const CartDropdown = () => {
  const { globalState } = useContext(CartContext);
  const { closeDropdown, open } = useContext(DropdownContext);

  return (
    <div
      sx={{
        display: "block",
        width: ["100vw", "450px"],
        height: open ? "500px" : "0px",
        overflow: "hidden",
        transition: "height 0.4s ease-out ",
        background: "white",
        position: "fixed",
        top: "0",
        right: "0",
        zIndex: "99",
        boxSizing: "border-box",
        borderBottom: open ? ["none", "1px solid #545454"] : "none",
        borderLeft: ["none", "1px solid #545454"],
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: 4,
          position: "relative",
        }}
      >
        <button
          onClick={closeDropdown}
          sx={{
            border: "none",
            outline: "none",
            height: "50px",
            width: "20px",
            padding: "0",
            position: "relative",
            cursor: "pointer",
            background: "transparent",
            ml: "auto",
          }}
        >
          <div
            sx={{
              position: "relative",
              height: "50px",
              width: "20px",
            }}
          >
            <div
              sx={{
                position: "absolute",
                top: "50%",
                left: ["12px", "50%"],
                transform: "translate(-50%, -50%)",
                span: {
                  display: "block",
                  padding: "0",
                  height: "2px",
                  backgroundColor: "text",
                  my: "5px",
                  borderRadius: "2px",
                  width: "20px",
                  transition: "ease-in-out 0.3s",
                },
              }}
            >
              <span
                sx={{ transform: "rotate(-135deg) translate(-3px, -2px)" }}
              />
              <span sx={{ transform: "rotate(135deg) translate(-3px, 2px)" }} />
            </div>
          </div>
        </button>
        <h2
          sx={{
            textAlign: "center",
            fontSize: 5,
            textTransform: "uppercase",
            mb: 4,
            color: "text",
          }}
        >
          Added to Cart
        </h2>

        <div sx={{ display: "flex", height: "200px" }}>
          <div
            sx={{
              flex: 4,
              div: {
                height: "100%",
              },
            }}
          >
            {globalState.lastAddedProductImage && (
              <Img
                fluid={
                  globalState.lastAddedProductImage.image.childImageSharp.fluid
                }
                alt={globalState.lastAddedProductImage.alt}
              />
            )}
          </div>
          <div
            sx={{
              flex: 3,
              p: 3,
            }}
          >
            <h3 sx={{ mb: 2, fontSize: 3 }}>
              {globalState.lastAddedProductTitle}
            </h3>
            <p>Color: {globalState.lastAddedProductColor}</p>
            <p>Price: {globalState.lastAddedProductPrice}</p>
          </div>
        </div>
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 5,
            p: {
              color: "text",
            },
          }}
        >
          <p>{`${globalState.total} ITEM(S), SUB-TOTAL: `}</p>
          <p>{globalState.totalprice} SEK</p>
        </div>
        <Link
          to="/shopping_cart"
          sx={{
            pointer: "cursor",
            textAlign: "center",
            boxSizing: "border-box",
            textDecoration: "none",
            border: "1px solid",
            borderColor: "primary",
            backgroundColor: "primary",
            color: "white",
            paddingX: 3,
            paddingY: "12px",
            width: "100%",
            textTransform: "uppercase",
            fontSize: 0,
          }}
        >
          Go to checkout
        </Link>
      </div>
    </div>
  );
};

export default CartDropdown;
