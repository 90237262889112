/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql, useStaticQuery, Link } from "gatsby";
import ExternalLink from "./ExternalLink";

const StyledTitle = ({ title }) => (
  <h3 sx={{ fontSize: [3], color: "details", mb: "8px" }}>{title}</h3>
);

const FooterContainer = ({ children }) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["column", "row", "row"],
      justifyContent: ["space-between"],
      maxWidth: "2000px",
      mx: "auto",
    }}
  >
    {children}
  </div>
);

const Footer = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ContactQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "contact-page" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                twitter
                linkedin
                instagram
                facebook
                contact {
                  address
                  city
                  email
                  name
                  phone
                }
              }
            }
          }
        }
      }
    `
  );

  const {
    contact,
    twitter,
    linkedin,
    instagram,
    facebook,
  } = allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <footer
      sx={{
        backgroundColor: "black",
        p: [4, 5],
        height: ["650px", "350px"],
      }}
    >
      <FooterContainer>
        <section sx={{ display: "flex", flexDirection: ["column", "row"] }}>
          <div
            sx={{
              p: {
                color: "white",
                py: 1,
                fontSize: 1,
                lineHeight: 1.5,
              },
              a: { fontSize: 1, lineHeight: 1.5 },
            }}
          >
            <StyledTitle title={contact[0].name} />
            <p>{contact[0].address}</p>
            <p>{contact[0].city}</p>
            <div
              sx={{
                a: { fontSize: 1, lineHeight: 1.5 },
              }}
            >
              <ExternalLink color='white' url={`tel:${contact[0].phone}`}>
                T: {contact[0].phone}
              </ExternalLink>
            </div>
            <ExternalLink color='white' url={`mailto:${contact[0].email}`}>
              {contact[0].email}
            </ExternalLink>
          </div>
          <div
            sx={{
              mt: [4, "0px"],
              ml: [0, 7],
              a: { fontSize: 1, lineHeight: 1.5 },
            }}
          >
            <StyledTitle title='SOCIAL' />
            <div>
              {facebook && (
                <ExternalLink color='white' url={facebook}>
                  Facebook
                </ExternalLink>
              )}
            </div>
            <div>
              {linkedin && (
                <ExternalLink color='white' url={linkedin}>
                  Linkedin
                </ExternalLink>
              )}
            </div>
            <div>
              {instagram && (
                <ExternalLink color='white' url={instagram}>
                  Instagram
                </ExternalLink>
              )}
            </div>
            <div>
              {twitter && (
                <ExternalLink color='white' url={twitter}>
                  Twitter
                </ExternalLink>
              )}
            </div>
          </div>
        </section>
        <div sx={{ mt: [4, "0px"] }}>
          <StyledTitle title='PRIVACY POLICY' />
          <div sx={{ a: { fontSize: 0, lineHeight: 1.5 } }}>
            <Link
              to='/gdpr'
              sx={{
                color: "white",
                py: 1,
                position: "relative",
                overflow: "hidden",
                display: "inline-block",
                ":after": {
                  content: '" "',
                  position: "absolute",
                  right: 0,
                  width: 0,
                  top: "28px",
                  background: "white",
                  height: "1px",
                  transition: "width 0.3s ease-out",
                },
                ":hover, :focus, :active": {
                  color: "white",
                  ":after": {
                    left: 0,
                    right: "auto",
                    width: "100%",
                  },
                },
              }}
            >
              GDPR
            </Link>
          </div>
          <Link
            to='/terms_and_conditions'
            sx={{
              color: "white",
              py: 1,
              position: "relative",
              overflow: "hidden",
              display: "inline-block",
              ":after": {
                content: '" "',
                position: "absolute",
                right: 0,
                width: 0,
                top: "28px",
                background: "white",
                height: "1px",
                transition: "width 0.3s ease-out",
              },
              ":hover, :focus, :active": {
                color: "white",
                ":after": {
                  left: 0,
                  right: "auto",
                  width: "100%",
                },
              },
            }}
          >
            Terms &#38; Conditions
          </Link>
        </div>
      </FooterContainer>
      <div sx={{ height: "1px", backgroundColor: "details", my: 4 }} />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: ["flex-start", "center"],
          maxWidth: "2000px",
          mx: "auto",
        }}
      >
        <div
          sx={{
            height: "40px",
            img: { objectFit: "cover", height: "100%" },
          }}
        >
          <img
            src='https://x.klarnacdn.net/payment-method/assets/badges/generic/black/klarna.png'
            alt='klarna logo'
          />
        </div>
        <ExternalLink
          color='white'
          url='https://www.snackbarstudios.se/'
          fontSize='12px'
        >
          <span
            sx={{
              color: "details",
              fontSize: 0,
            }}
          >
            Powered by Snackbar Studios
          </span>
        </ExternalLink>
        <p
          sx={{
            color: "details",
            fontSize: 0,
          }}
        >
          © {new Date().getFullYear()} {contact[0].name}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
