import { useContext } from "react";
import { CartContext } from "../providers/CartProvider";
import {
  ADD_PRODUCT,
  SUBTRACT_PRODUCT,
  REMOVE_PRODUCT,
  INITIALIZE_CART,
} from "../providers/CartReducer";

export const useCart = () => {
  const { state, dispatch } = useContext(CartContext);

  const addProductToCart = (node, color) => {
    dispatch({ type: ADD_PRODUCT, payload: { node, color } });
  };
  const addProductFromCheckout = (node, color) => {
    dispatch({ type: ADD_PRODUCT, payload: { node, color } });
  };
  const subtractProductFromCart = (node, color) => {
    dispatch({ type: SUBTRACT_PRODUCT, payload: { node, color } });
  };
  const removeProductFromCart = (node, color) => {
    dispatch({ type: REMOVE_PRODUCT, payload: { node, color } });
  };
  const initializeCart = () => {
    dispatch({ type: INITIALIZE_CART });
  };
  return {
    state,
    dispatch,
    addProductToCart,
    subtractProductFromCart,
    removeProductFromCart,
    initializeCart,
    addProductFromCheckout,
  };
};
